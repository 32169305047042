<template>
    <div class="server-wrap">
        <el-row :gutter="24">
            <el-col :span="6">
                <div class="grid-content bg-purple" style="background: rgb(49, 180, 141);">
                    <p>
                        <span>当前统计系统es文件数</span>
                        <span>实时</span>
                    </p>
                    <div>{{esTotal}}个</div>
                    <p class="infos"></p>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple" style="background:rgb(56, 161, 242);">
                    <p>
                        <span>当前统计系统文件数</span>
                        <span>实时</span>
                    </p>
                    <div>{{sysTotal}}个</div>
                    <p class="infos"></p>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple" style="background: rgb(117, 56, 199);">
                    <p>
                        <span>当前统计系统文件存储大小数</span>
                        <span>实时</span>
                    </p>
                    <div>{{sysInfosTotal}}GB</div>
                    <p class="infos"></p>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple" style="background: rgb(59, 103, 164);">
                    <p>
                        <span>当前统计共享数</span>
                        <span>实时</span>
                    </p>
                    <div>{{shareTotal}}次</div>
                    <p class="infos"></p>
                </div>
            </el-col>
             <el-col :span="6">
                <div class="grid-content bg-purple" style="background: #e6a23c;">
                    <p>
                        <span>当前统计请求数</span>
                        <span>实时</span>
                    </p>
                    <div>{{shareRequreTotal}}次</div>
                    <p class="infos"></p>
                </div>
            </el-col>
        </el-row>
        <div>
            <el-row :gutter="24" style="margin-bottom: 20px;">
                <el-col :span="12" class="card-box top">
                    <el-card>
                        <div slot="header" class="card-heads">
                            <span>共享次数量排行榜</span>
                            <el-select v-model="shareMaxCounts" placeholder="请选择" @change="selectShareChange">
                                <el-option
                                v-for="item in shareOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div id="shareMyEcharts" style="width: 550px;height: 300px"></div>
                    </el-card>
                </el-col>
                <el-col :span="12" class="card-box top">
                    <el-card>
                        <div slot="header" class="card-heads">
                            <span>请求次数量排行榜</span>
                            <el-select v-model="requestMaxCounts" placeholder="请选择" @change="selectRequestChange">
                                <el-option
                                v-for="item in requestOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div id="requestMyEcharts" style="width: 550px;height: 300px"></div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div>
             <el-row :gutter="24" style="margin-bottom: 20px;">
                <el-col :span="12" class="card-box top">
                    <el-card>
                        <div slot="header" class="card-heads">
                            <span>阅读次数量排行榜</span>
                            <el-select v-model="readMaxCounts" placeholder="请选择" @change="selectReadChange">
                                <el-option
                                v-for="item in readOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div id="readMyEcharts" style="width: 550px;height: 300px"></div>
                    </el-card>
                </el-col>
                <el-col :span="12" class="card-box top">
                    <el-card>
                        <div slot="header" class="card-heads">
                            <span>排行榜</span>
                        </div>
                        <div id="rankMyEcharts" style="width: 550px; height: 300px;"></div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div>
             <el-row :gutter="24" style="margin-bottom: 20px;">
                <el-col :span="24" class="card-box top">
                    <el-card>
                        <div slot="header">
                            <h2 class="charts-title">系统三十天文件增长量排行</h2>
                        </div>
                        <div id="myEchartsBox" style="width: 1200px;height: 400px;"></div>
                    </el-card>
                </el-col>
             </el-row>
        </div>
    </div>
</template>
<script>
import * as mecharts from 'echarts'
import EchartsMixins from './echartsMixins.js'
import { monitormanagerAPI } from 'liankong-ui-api'

export default {
    mixins: [EchartsMixins],
    data() {
        return {
            option: {},
            myEcharts: '',
            esTotal: 0,
            sysTotal: 0,
            sysInfosTotal: 0,
            monthTotalList: [], // 存放30天的总数据
            monthList: [], // 存放30天的数据
            monthDataList: [], // 存放30天对应的数据
            shareTotal: 0,
            shareRequreTotal: 0,
            shareOptions: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }, {
                value: '3',
                label: '周'
            }],
            requestOptions: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }, {
                value: '3',
                label: '周'
            }],
            readOptions: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }, {
                value: '3',
                label: '周'
            }],
            shareMaxCounts: '0',
            requestMaxCounts: '0',
            readMaxCounts: '0',
            shareEchartsyXisData: [10, 52, 200, 334, 390, 330, 220],
            requestEchartsyXisData: [100,40,270,330,300,210,170],
            readEchartsyXisData: [50,140,170,230,200,110,370],
            rankMyEchartsyXisData: [200,100,150,300,320,100,30]
        }
    },
    mounted(){
        this.getEsList();
        this.getSysLists();
        this.getSysInfosLists();
        this.getShareList();
        this.getSysRankLists();

        this.getSelectShareList();
        this.getSelectRequestList();
        this.getSelectReadList();
        this.getRanksList();
    },
    methods: {
        // 查询系统文件总数
        getEsList(){
            monitormanagerAPI.getSysEsTotalList().then(res=>{
                if(res.code == '0') {
                    this.esTotal = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 系统文件总数
        getSysLists(){
            monitormanagerAPI.getSysTotalList().then(res=>{
                if(res.code == '0') {
                    this.sysTotal = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 系统信息
        getSysInfosLists(){
            monitormanagerAPI.getSysInfosList().then(res=>{
                if(res.code == '0') {
                    this.sysInfosTotal = res.data.totalSize;

                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 共享和请求
        getShareList(){
            monitormanagerAPI.getShareInfosList().then(res=>{
                if(res.code == '0') {
                    this.shareTotal = res.data.shareCount;
                    this.shareRequreTotal = res.data.shareRequestConut;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 系统三十天的统计情况
        getSysRankLists(){
            this.monthList = [];
            monitormanagerAPI.getRankingList().then(res=>{
                if(res.code == '0') {
                    this.monthTotalList = res.data;
                    this.monthTotalList.forEach(item=>{
                        this.monthList.unshift(item.date)
                        this.monthDataList.unshift(item.size)
                    })
                    this.onInit();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        onInit(){
                this.myEcharts = mecharts.init(document.getElementById('myEchartsBox'));

                this.option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            rotate: 40
                        },
                        data: this.monthList
                    },
                    yAxis: {
                        type: 'value',
                        name: '单位：MB',
                        nameTextStyle: {
                            padding: [0, 40, 10, 0]
                        }
                    },
                    series: [
                        {
                            data: this.monthDataList,
                            type: 'line'
                        }
                    ]
                };

                this.option && this.myEcharts.setOption(this.option);
        },
        // 共享次数量排行榜-下拉框
        selectShareChange(val){
           this.shareMaxCounts = val;

           this.getSelectShareList();
        },
        getSelectShareList(){
            // 请求接口 获取数据 改变图标
            // 请求接口--在这里
            this.shareOnInit('shareMyEcharts', this.shareEchartsyXisData);
        },
        // 请求次数量排行榜-下拉框
        selectRequestChange(val){
            this.requestMaxCounts = val;
        },
        getSelectRequestList(){
           this.shareOnInit('requestMyEcharts', this.requestEchartsyXisData);
        },
        // 阅读次数量排行榜-下拉框
        selectReadChange(val){
            this.readMaxCounts = val;
        },
        getSelectReadList(){
            this.shareOnInit('readMyEcharts', this.readEchartsyXisData);
        },
        getRanksList() {
            this.shareOnInit('rankMyEcharts', this.rankMyEchartsyXisData);
        }
    }
}
</script>
<style lang="less" scoped>
.server-wrap {
    padding: 25px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.el-col {
    border-radius: 4px;
}
.bg-purple {
    cursor: pointer;
    background: #eee;
    margin: 10px 0;
    p:nth-child(1) {
        padding: 10px 20px 0px 20px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        span:nth-child(2){
            background: rgba(255, 255, 255, .2);
            padding: 5px;
            border-radius: 3px;
        }
    }
    div {
        padding: 0 20px;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
    }
    .infos {
        padding: 0 20px 10px 20px;
    }
}
.grid-content {
    border-radius: 4px;
    min-height: 36px;
}
.charts-title {
    font-size: 18px;
    margin: 0;
    padding: 0;
}
.card-heads {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .el-input.el-input--suffix {
        width: 150px !important;
    }
}
/deep/ .el-card__header {
    padding: 13px 20px;
}
</style>