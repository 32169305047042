import * as mecharts from 'echarts'
export default {
    data() {
        return {}
    },
    created() {
    },
    methods: {
        shareOnInit(documentNames, yData) {
            let shareMyEcharts = mecharts.init(document.getElementById(documentNames));

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisTick: {
                        alignWithLabel: true
                    }
                }],
                yAxis: [{
                    type: 'value'
                }],
                series: [{
                    name: '',
                    type: 'bar',
                    barWidth: '60%',
                    data: yData
                }]
            };

            option && shareMyEcharts.setOption(option);
        }
    }
}